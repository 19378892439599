import React from "react";
import { usePageName } from "react-page-name";
import "../notfound/notfound.scss"

const NotFound = () => {

    usePageName(`News | Jakob Augschöll`);

    return (
        <div className="notfound">
            <div className="container">
                <h2>404</h2>
                <h4>Hallo, nett hier?</h4>
                <p>
                    Jedoch ist diese Seite leider nicht mehr verfügbar oder du hast dich ganz einfach nur vertippt.
                </p>
            </div>
        </div>
    )
};

export default NotFound